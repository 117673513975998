.app {
    font-family: sans-serif;
    text-align: center;
    margin: 0;
    max-width: 100vw;
    display: flex;
    /* height: 100%; */
    /* width: 100%; */
    
    
  }
  :root {
    --base: #dbdddf;
    --text: #fff;
    --primary-button: #7192be;
    --button-border: #efabff;
    --shadow: #05235c;
  }
  body {
    /* height: 100%; */
    /* width: 100%; */
    max-width: 100vw;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    padding: 0;
    background-color: var(--base); 
    color: var(--text);
  }
  
  .signature-canvas,canvas,
  .signature-image {
    border: 1px solid var(--button-border);
    /* width: 500px !important; */
    /* height: 100px !important; */
    /* position: absolute; */
    margin: 1rem;
    border-radius: 25px;
  }

  button {
    background-color: var(--primary-button);
    color: var(--text);
    padding: 0.25rem;
    min-width: 6rem;
    margin: 0.5rem;
    border: 1px solid var(--button-border);
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  button:hover {
    background-color: var(--text);
    box-shadow: 0px 2px 4px var(--shadow);
    color: var(--primary-button);
  }
  
